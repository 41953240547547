<template>
  <div class="child-container home">
    <el-carousel ref="carouselRef" direction="vertical" :interval="interval" height="1080px" class="carousel" arrow="never">
      <el-carousel-item v-for="(item, index) in bannerList" :key="'item' + index">
        <div class="proj-item">
          <img :src="item.imgList[0] && item.imgList[0].url" alt height="1080px" @click="toDetail(item)">
          <div class="proj-des" :style="{ marginTop: (35 * index) + 'px' }">
            <div class="des1">{{item.title}}</div>
            <div class="des2">{{item.des1 || item.des2 || ''}}</div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <div class="filter-content flex">
      <div class="filter-item" v-for="(item, index) in filterList" :key="item + index" @click="filterShow(item, index)"
        :class="{'active': active == index}">{{item.name}}</div>
    </div>

    <div class="img-content flex">
      <div class="img-item" v-for="(item, index) in projectList" :key="item._id + index"  @click="toDetail(item)">
        <el-image lazy :src="item.imgList[0] && item.imgList[0].url"></el-image>
        <div class="item-des">
          <div class="des1">{{item.des1 || item.title}}</div>
        </div>
        <div class="item-title">{{item.title}}</div>
      </div>
    </div>

    <div class="pagination-content">
      <el-pagination
        :page-size="pageSize"
        layout="pager"
        @current-change="getProjectList"
        :current-page.sync="page"
        :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import { filterList } from '../utils/Const'
export default {
  name: "Home",
  components: {},
  data() {
    return {
      filterList,
      active: 0,
      bannerList: [],
      projectList: [],
      type: 'all',
      page: 1,
      pageSize: 18,
      total: 0,
      interval: 4000
    };
  },
  created() {
    this.getBannerList();
    this.getProjectList();
  },
  methods: {
    getBannerList() {
      this.$http.get(`/project?type=all&bannerType=2&page=1&pageSize=5`).then((res) => {
        this.bannerList = res.data.list
      });
    },
    getProjectList() {
      const { page, pageSize, type = 'all' } = this
      this.$http.get(`/project?type=${type}&bannerType=1&page=${page}&pageSize=${pageSize}`).then((res) => {
        this.projectList = res.data.list
        this.total = res.data.total
      });
    },
    filterShow(item, index) {
      this.active = index
      this.type = item.type
      this.getProjectList()
    },
    toDetail({ _id }) {
      this.$router.push({ path: "/detail", query: { _id } });
    }
  }
};
</script>

<style lang="less" scoped>
.filter-content {
  margin: 100px auto;
  width: 1200px;
  .filter-item {
    color: #555555;
    font-size: 24px;
    line-height: 24px;
    cursor: pointer;
    + .filter-item {
      margin-left: 80px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: -40px;
        top: 0;
        background: #555555;
        height: 24px;
        width: 1px;
      }
    }
    &:hover, &.active {
      font-weight: bold;
      color: #111111;
    }
  }
}

.item-des {
  height: 42px;
  position: absolute;
  background: rgba(255,255,255,.8);
  bottom: 40px;
  padding: 20px 0;
  transition: width .45s cubic-bezier(.215,.61,.355,1);
  .des1 {
    font-size: 18px;
    color: #4D4F52;
    line-height: 1.2;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding: 0 32px;
    display: none;
  }
  .des2 {
    margin-top: 5px;
    font-size: 18px;
    color: #4D4F52;
    padding: 0 32px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: none;
  }
}

.img-content {
  flex-wrap: wrap;
  width: 1200px;
  margin: auto;
  .img-item {
    margin: 0 64px 64px 0;
    cursor: pointer;
    position: relative;
    &:nth-child(2n) {
      margin: 0 0 64px 0;
    }
    /deep/ .el-image {
      width: 567px;
      min-width: 567px;
      max-width: 567px;
      height: 376px;
      > img {
        width: 100%;
        height: 376px;
        object-fit: fill;
      }
    }
    .item-title {
      margin-top: 10px;
      font-size: 18px;
      color: #4D4F52;
      font-weight: 600;
    }
  }
}

/deep/ .el-carousel__indicators--vertical {
  left: 50px;
  bottom: 200px;
  top: auto;
  width: 36px;
  .el-carousel__indicator--vertical {
    padding: 10px;
    .el-carousel__button {
      width: 15px;
      height: 15px;
      background: #C2C2C2;
      border-radius: 50%;
    }
    &.is-active .el-carousel__button {
      width: 15px;
      height: 66px;
      background: #7D7D7D;
      border-radius: 75px;
    }
  }
}

.pagination-content {
  text-align: center;
  margin-top: 60px;
}

.proj-item {
  img {
    width: 100%;
    object-fit: cover;
  }
  .proj-des {
    position: relative;
    bottom: 434px;
    left: 95px;
    .des1 {
      font-size: 30px;
      color: #5C5C5C;
      line-height: 36px;
      font-weight: bold;
    }
    .des2 {
      font-size: 18px;
      color: #5C5C5C;
      line-height: 36px;
      font-weight: 200;
    }
  }
}

.img-item:hover {
  .item-des {
    width: 100%;
    display: block;
    transition: width .45s cubic-bezier(.215,.61,.355,1);
    .des1, .des2 {
      display: -webkit-box;
    }
  }
}
/deep/ .el-pager li {
  font-size: 24px;
  color: #999999;
  font-weight: 200;
  &.active {
    color: #4D4F52;
    font-weight: bold;
  }
}

</style>
